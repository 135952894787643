<template>
  <div>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-container>
          Danke, dass du den {{id}} Test gemacht hast!
        </v-container>
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="value"
          color="primary"
        >
          {{ value }}
        </v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { redirect } from '@/utils/redirect';

export default {
  name: 'Single',
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    setInterval(() => {
      if (this.value < 100) {
        this.value += 10;
      }
    }, 500);
  },
  watch: {
    value() {
      if (this.value === 100) redirect('https://www.profemina.org/');
    },
  },
  computed: {
    ...mapGetters('testMicro', [
      'id',
    ]),
  },
};
</script>
